import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";

/**
 * @param empresas                 Lista de empresas
 * @param state
 * @param definirComoPrincipal     Booleano para que indica se a empresa será definida como principal
 * @param setDefinirComoPrincipal  Altera o booleano definirComoPrincipal para !definirComoPrincipal
 * @param removerCnpj
 * @returns
 */
function Empresas({
                    empresas = [],
                    state = {},
                    definirComoPrincipal = [],
                    setDefinirComoPrincipal = () => {
                    },
                    removerCnpj = (cnpj) => {
                    },
                    vinculoEmpresaTipos = [],
                    displayAccordionContent = {},
                    displayAccordionContentShowError = {},
                    displayAccordionContentIsShowError = {}
                  }) {
  const CustomSelect = ({cnpj, vinculos}) => {

    const [selected, setSelected] = useState(vinculos && vinculos.length && vinculos[0]);
    const [isActive, setIsActive] = useState(false);
    const selectRef = useRef(null);

    const toggleDropdown = () => {
      setIsActive(prevState => !prevState);
    };

    const handleOptionClick = (type) => {
      setSelected(type);
      setIsActive(false);
      if (type.descricao.toLowerCase() != 'proprietário ou sócio'){
        displayAccordionContentShowError = false;
      } else if (displayAccordionContentIsShowError == true){
        displayAccordionContentShowError = true;
      }
    };

    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

  function formatDesTipoVinculo(desTipoVinculo) {
     return desTipoVinculo.split(' ')
                .map(letter => letter[0].toUpperCase() === "O" ? letter[0].toLowerCase() + letter.substring(1).toLowerCase() : letter[0].toUpperCase() + letter.substring(1).toLowerCase())
                .join(' ')
  };

    return (
      <div className="custom-select" ref={selectRef} onClick={handleClickOutside} data-to={cnpj} data-selected={selected ? selected.id : null}>
        <div
          className={`select-selected ${displayAccordionContentShowError ? 'set-error-color' : ''} ${isActive ? 'active' : ''}`}
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering the outside click handler
            toggleDropdown();
          }}
        >
          {formatDesTipoVinculo(selected.descricao)}
        </div>     


        <div className="select-items">
          {vinculos.map(item =>
            <div
              data-value={item.id}
              key={item.id}
              className={selected.descricao === item.descricao ? 'active' : ''}
              onClick={() => handleOptionClick(item)}
            >
              {formatDesTipoVinculo(item.descricao)}
            </div>
          )}
        </div>
        <div className={`select-arrow ${isActive ? 'down' : ''}`} onClick={toggleDropdown}></div>
        {displayAccordionContentShowError ? 
        <div class="sb-components__form__ipt-group error">
            <span class="set-error-vinculo">Vínculo selecionado não corresponde ao CNPJ informado.</span>
        </div> : ''}
      </div>
    );
  }

  function VinculoEmpresasTipos({cnpj}) {
    return (
      <CustomSelect cnpj={cnpj} vinculos={vinculoEmpresaTipos}/>
    )
  }

  function formatCnpj(cnpj = "") {
    if (!cnpj || cnpj.length !== 14) return cnpj;
    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      "$1.$2.$3/$4-$5"
    );
  }

  /**
   01 = Nula;
   02 = Ativa;
   03 = Suspensa;
   04 = Inapta;
   08 = Baixada;
   99 = Sem Retorno.
   */
  function formatSituacaoCadastral(situacaoCadastral = "") {
    return ["02", "04", "99"].find((s) => s == situacaoCadastral)
      ? "Ativo"
      : "Baixada";
  }

  function handleSetDefinirComoPrincipal(cnpj) {
    if (definirComoPrincipal === cnpj) {
      setDefinirComoPrincipal("");
    } else {
      setDefinirComoPrincipal(cnpj);
    }
  }

  function displayAccordionFunction (){
    if(displayAccordionContent){
      return {
        display: `block`,
      }
      // document.getElementsByClassName(".sb-components-accordion-content")[0].style.display = "block";
    }else{
      return {
        display: `none`,
      }
      // document.getElementsByClassName(".sb-components-accordion-content")[0].style.display = "none";
    }
  }

  return empresas.length ? (
    empresas.map((empresa, index) => (
      <li key={empresa.cnpj}>
        <button
          type="button"
          class="sb-components-accordion-open sb-not-scroll"
          data-sb-acao="clique"
          data-sb-rotulo="empresa"
        >


          {empresa.nomeFantasia ?? empresa.razaoSocial}
        </button>
        <div class="sb-components-accordion-content" style={displayAccordionFunction()} >
          <div className="sb-components__table" id="empresasVincular">
            <table>
              <tbody>
              <tr>
                <td>
                  <strong>CNPJ:</strong>
                </td>

                <td align="right">
                  {formatCnpj(empresa.cnpj)}
                </td>
              </tr>
              <tr id='select-vinculo'>
                <td>
                  <strong>Tipo de vínculo:</strong>
                </td>
                {/* {getTipoVinculo(empresa)} */}
                <td align="right"><VinculoEmpresasTipos cnpj={empresa.cnpj}/></td>
              </tr>
              <tr>
                <td class="situacion">
                  <strong>Situação:</strong>
                </td>
                <td align="right">
                  {formatSituacaoCadastral(empresa.situacaoCadastral)}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <span className="modal-cad-pj__trash-button btn-top" onClick={() => removerCnpj(empresa.cnpj)}></span>
          <button
            type="button"
            data-sb-acao="clique"
            data-sb-rotulo="principal"
            class={`modal-cad-pj__favorite-button ${
              definirComoPrincipal === empresa.cnpj ? "active" : ""
            }`}
            onClick={() => handleSetDefinirComoPrincipal(empresa.cnpj)}
          >{definirComoPrincipal === empresa.cnpj ? "Prioritário" : "Definir como prioritário"}
          </button>
        </div>
      </li>
    ))
  ) : (
    <></>
  );
}

Empresas.protoTypes = {
  empresas: PropTypes.array,
  state: PropTypes.object,
  definirComoPrincipal: PropTypes.string,
  setDefinirComoPrincipal: PropTypes.func,
  displayAccordionContent: PropTypes.bool,
  displayAccordionContentShowError: PropTypes.bool
};

export default Empresas;
