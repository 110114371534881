import React from "react";
import PropTypes from "prop-types";

function ErrorModalInvalid422({ message, closeModal }) {
  return (
      <>
        <div className="sb-components__alert error">
          <strong>O vínculo selecionado não corresponde ao CNPJ informado.</strong>
          <p>{message}</p>
        </div>
        <div className="sb-components__form__buttons">
          <a
            href="#modal-vinculo-tag"
            className="sb-components__button inline radius-4 small-btn"
            onClick={closeModal}
            data-sb-acao="clique"
            data-sb-rotulo="Fechar"
          >
            Tentar Novamente
          </a>
        </div>
      </>
  );
}

ErrorModalInvalid422.protoTypes = {
  message: PropTypes.string,
  closeModal: PropTypes.func,
};

export default ErrorModalInvalid422;
