import React, { useEffect, useRef, useState } from "react";
import { useGlobalState } from "../context/AppContext";
import $ from "jquery";

import { useCpe } from "./useCpe";
import Empresas from "./Empresas";
import FooterModal from "./FooterModal";
import HeaderModal from "./HeaderModal";
import EmpresasGrandeMedioPorte from "./EmpresasGrandeMedioPorte";
import CadastrarEmpresaModal from "./CadastrarEmpresaModal";
import SuccessModal from "../modalVinculoEmpresa/SuccessModal";
import ErrorModal from "../modalVinculoEmpresa/ErrorModal";
import ErrorModalInvalid422 from "../modalVinculoEmpresa/ErrorModalInvalid422";
import LoadingModal from "../modalVinculoEmpresa/LoadingModal";

export const dictMessagesPJ = {
  PERFIL_DONO_EPP: `Vincule o CNPJ da sua empresa ao seu cadastro e tenha uma experiência mais
            personalizada e acesso a mais serviços do Portal Sebrae. Basta inserir o seu
            CNPJ e clicar em "Adicionar".`,
  PERFIL_DONO_ME: `Vincule o CNPJ da sua empresa ao seu cadastro e tenha uma experiência mais
            personalizada e acesso a mais serviços do Portal Sebrae. Basta inserir o seu
            CNPJ e clicar em "Adicionar".`,

  PERFIL_COOPERATIVA: `Vincule o CNPJ da sua Sociedade Cooperativa ao seu cadastro e tenha uma
            experiência mais personalizada e acesso a mais serviços do Portal Sebrae.
            Basta inserir o seu CNPJ e clicar em "Adicionar".`,

  PERFIL_FUNCIONARIO_EPP: `Vincule o CNPJ da empresa onde trabalha ao seu cadastro e tenha uma
            experiência mais personalizada e acesso a mais serviços do Portal Sebrae.
            Basta inserir o seu CNPJ e clicar em "Adicionar".`,
  PERFIL_FUNCIONARIO_ME: `Vincule o CNPJ da empresa onde trabalha ao seu cadastro e tenha uma
            experiência mais personalizada e acesso a mais serviços do Portal Sebrae.
            Basta inserir o seu CNPJ e clicar em "Adicionar".`,

  PERFIL_MEI: `Vincule o CNPJ do seu MEI ao seu cadastro e tenha uma experiência
            mais personalizada e acesso a mais serviços do Portal Sebrae.
            Basta inserir o seu CNPJ e clicar em "Adicionar".`,
  PERFIL_STARTUP: `Sua Startup possui CPNJ? Vincule aqui para ter uma experiência mais
            personalizada e acesso a mais serviços do Portal Sebrae. Basta inserir o
            seu CNPJ e clicar em "Adicionar".`,
  default: `Informe o CNPJ da empresa que você tem vínculo para continuar.`,
};

export const porte = {
  99: "MEI",
  1: "médio",
  2: "micro",
  3: "pequeno",
  4: "grande",
  98: "outro",
  5: "outro",
};

/**
 *
 * @param props.perfil
 * @param props.show  booleano utilizado para fazer a modal aparecer/desaparecer
 * @param props.onChangeShow  função que altera a props.show
 * @param props.cnpj
 * @returns
 */
export default function ModalCadastroPJ(props) {
  const perfil = props.perfil || {};
  const messagePJ =
    dictMessagesPJ[perfil.labelDominio || "default"] ||
    dictMessagesPJ["default"];

  const [state] = useGlobalState();
  const [cnpj, setCnpj] = useState("");
  const [cnpjShowing, setCnpjShowing] = useState("");
  const [mensagemErroCnpj, setMensagemErroCnpj] = useState("");
  const [empresaName, setEmpresaName] = useState([]);
  const [empresaPorte, setEmpresasPorte] = useState([]);
  const [empresasTable, setEmpresasTable] = useState([]);

  const [empresa, setEmpresa] = useState();

  const [empresasCadastradas, setEmpresasCadastradas] = useState(false);

  const [modalState, setModalState] = useState("NONE");

  const [definirComoPrincipal, setDefinirComoPrincipal] = useState("");

  const adicionarEmpresaRef = useRef(null);
  const cadastrarEmpresaRef = useRef(null);
  const modalPjInfoRef = useRef(null);
  const modalCadPjRef = useRef(null);
  const cadastroEmpresaRef = useRef(null);
  const pjErrorRef = useRef(null);
  const cnpjInputRef = useRef(null);

  const [displayAccordionContent, setDisplayAccordionContent] = useState(false);
  const [displayAccordionContentShowError, setDisplayAccordionContentShowError] = useState(false);
  const [displayAccordionContentIsShowError, setDisplayAccordionContentIsShowError] = useState(false);

  const [shouldRun, setShouldRun] = useState(false);

  const [empresasAdicionadas, setEmpresasAdicionadas] = useState([])
  const [vinculoEmpresaTipos, setVinculoEmpresaTipos] = useState([])

  const { postPjVincular, getPj, vincularPjSettingPrincipal, getVinculoEmpresaTipo } = useCpe();

  useEffect(() => {
    if (props.cnpj) {
      handleCnpj({ nativeEvent: {}, target: { value: props.cnpj } }, true);
      setShouldRun(true);
      //ToDo
    }
    getVinculoEmpresaTipo().then(data => setVinculoEmpresaTipos(data.data))
  }, []);
  console.log(vinculoEmpresaTipos)

  useEffect(() => {
    if (props.cnpj && shouldRun) {
      adicionarEmpresa(props.cnpj);
      setShouldRun(false);
    }
  }, [cnpj]);

  useEffect(() => isShow(props.show), [props.show]);


  useEffect(() => {
    if(modalState === 'RESET') {
      $(adicionarEmpresaRef.current).removeClass("disabled");
      $(cadastroEmpresaRef.current).removeAttr("disabled");

      setCnpj("");
      setCnpjShowing("");
      setEmpresasTable([]);
      setEmpresa({});
      setModalState('NONE')
      setDefinirComoPrincipal("")
    }
  },[modalState])


  function adicionarEmpresa() {
    if (empresasAdicionadas.some((e) => e === cnpj)) {
      setMensagemErroCnpj("CNPJ já inserido");
      cnpjInputRef.current.classList.add("error"); 
      $(pjErrorRef.current).prop("hidden", false);
      return;
    }
    if (
      props.empresasCPE &&
      props.empresasCPE.find((elem) => elem.cnpj === cnpj)
    ) {
      setMensagemErroCnpj("CNPJ já  cadastrado");
      cnpjInputRef.current.classList.add("error");
      $(pjErrorRef.current).prop("hidden", false);
      return;
    }
    if (!cnpj) {
      setMensagemErroCnpj("Adicione pelo menos um CNPJ");
      cnpjInputRef.current.classList.add("error");
      $(pjErrorRef.current).prop("hidden", false);
    } else if (cnpj.length !== 14) {
      setMensagemErroCnpj(
        "CNPJ inválido. Deve conter 14 digitos (utilize apenas números)."
      );
      cnpjInputRef.current.classList.add("error");
      $(pjErrorRef.current).prop("hidden", false);
    } else {
      cnpjInputRef.current.classList.remove("error");
      $(pjErrorRef.current).prop("hidden", true);
      setModalState("LOADING");
      getPj(
        cnpj,
        (res) => {
          if (res.status === 200 && props.show) {
            let empresa = res.data;
            empresa["parceira"] = false;
            setEmpresa(empresa);
            setEmpresasTable([empresa]);
            setDisplayAccordionContent(false);
            setDisplayAccordionContentShowError(false);
            setDisplayAccordionContentIsShowError(false);
            setModalState("ADDING");
            if(porte[empresa.porte]) {
              setEmpresasPorte(porte[empresa.porte]);
            }
            if (
              empresa["porte"] !== 3 &&
              empresa["porte"] !== 2 &&
              empresa["porte"] !== 99
            ) {
              $(adicionarEmpresaRef.current).addClass("disabled");
              $(cadastrarEmpresaRef.current).addClass("disabled");
              $(modalPjInfoRef.current).prop("hidden", false);
              setEmpresaName(empresa.nomeFantasia);

            }
          } else if (res.status === 404) {
            setModalState("INEXISTENTE");
          } else {
            setMensagemErroCnpj(
              "Ocorreu um erro ao buscar esse CNPJ na base de dados"
            );
            cnpjInputRef.current.classList.add("error");
            $(pjErrorRef.current).prop("hidden", false);
            setDisplayAccordionContent(false);
            setDisplayAccordionContentIsShowError(false);
            setModalState("ADDING");
          }
        },
        () => {
          if (props.show) {
            setMensagemErroCnpj(
              "Ocorreu um erro ao buscar esse CNPJ na base de dados"
            );
              cnpjInputRef.current.classList.add("error");
              $(pjErrorRef.current).prop("hidden", false);
            setModalState("NONE");
          }
        }
      );
    }
  }

  function cancelarCadastroPJ() {
    if (localStorage.getItem("shouldRefreshPage") || empresasCadastradas) {
      localStorage.removeItem("shouldRefreshPage");
      window.location.reload();
      return
    }
    setModalState("NONE");
    $(cadastroEmpresaRef.current).val("");
    $(cadastroEmpresaRef.current).removeAttr("disabled");
    $(adicionarEmpresaRef.current).removeClass("disabled");
    $(modalPjInfoRef.current).prop("hidden", true);
    setCnpj("");
    setCnpjShowing("");
    setEmpresasTable([]);
    setEmpresa({});
    setDefinirComoPrincipal("")

    props.onChangeShow(false);


  }

  function reOpenModal() {
    setModalState("ADDING");
    setDisplayAccordionContent(true);
    setDisplayAccordionContentShowError(true);
    setDisplayAccordionContentIsShowError(true);
  }

  function finishCadastroPJ() {
    cancelarCadastroPJ();
    window.location.reload();
  }

  function handleCnpj(e) {
    // 00.000.000/0000-00
    const val = e.target.value;
    let temp = val.replace(/[^0-9]/g, "");
    setCnpj(temp);
    if (e.nativeEvent.inputType !== "deleteContentBackward") {
      if (temp.length < 5) {
        temp = temp.replace(/(\d{2})(\d+)?/g, "$1.$2");
      } else if (temp.length < 8) {
        temp = temp.replace(/(\d{2})(\d{3})(\d+)?/g, "$1.$2.$3");
      } else if (temp.length < 12) {
        temp = temp.replace(/(\d{2})(\d{3})(\d{3})(\d+)?/g, "$1.$2.$3/$4");
      } else {
        temp = temp.slice(0, 14);
        temp = temp.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d+)?/g,
          "$1.$2.$3/$4-$5"
        );
      }
      setCnpjShowing(temp);
    } else {
      if (
        val.length === 2 ||
        val.length === 6 ||
        val.length === 10 ||
        val.length === 15
      ) {
        setCnpjShowing(val.slice(0, val.length - 1));
      } else {
        setCnpjShowing(val);
      }
    }
  }

  function isEmpresaParceira(confirm) {
    if (confirm) {
      let empresas = [...empresasTable];
      empresas[empresasTable.length - 1]["parceira"] = true;
      setEmpresasTable(empresas);
    }
    // $(adicionarEmpresaRef.current).removeClass("disabled");
    $(cadastrarEmpresaRef.current).removeClass("disabled");
    $(modalPjInfoRef.current).prop("hidden", true);
    // $(cadastroEmpresaRef.current).val("");
  }

  function cadastrarEmpresaCpe() {
    window.open(`${state.env.CPE_FRONTEND_URL}/empresa?cnpj=${cnpj}`, "_self");
  }

  function isShow(show) {
    if (show) {
      $(modalCadPjRef.current).fadeIn("fast").addClass("active");
    } else {
      $(modalCadPjRef.current).fadeOut("fast").removeClass("active");
    }
  }


  function cadastrarEmpresas() {
    if (
      empresa.situacaoCadastral != "02" &&
      empresa.situacaoCadastral != "04" &&
      empresa.situacaoCadastral != "99"
    ) {
      setModalState("INVALID");
      return;
    }
    setModalState("LOADING");

    const payload = JSON.stringify([{...empresa, idTipoVinculo: $(`[data-to="${empresa.cnpj}"]`).attr("data-selected")}]);
    const errorCallback = (error) =>{
      if(error.response.status == 422){
        setModalState("COD_INVALID_422");
        console.log("retorno erro é 422");
      }else{
        setModalState("INVALID");
        console.log("retorno erro diferente de 422");
      }
      
    }
      
    const successCallback = () => {
      setEmpresasAdicionadas(prev => [...prev, cnpj])
      setEmpresasCadastradas(true);
      setModalState("OK");
      if(empresa["porte"] == 99) {
        localStorage.setItem("DAS_CNPJ_CADASTRADO", cnpj);
      }
    };
    const cnpjAdded = localStorage.getItem("CNPJ_ADDED") == "true";

    const codeInvalid422 = null;

    if (
      !cnpjAdded &&
      (!props.empresasCPE ||
        props.empresasCPE.length === 0 ||
        !props.empresasCPE.some(e => e.isPrincipal) ||
        definirComoPrincipal !== "")
    ) {
      if (!definirComoPrincipal) {
        vincularPjSettingPrincipal(
          payload,
          empresa.cnpj,
          successCallback,
          errorCallback
        );
      } else {
        vincularPjSettingPrincipal(
          payload,
          definirComoPrincipal,
          successCallback,
          errorCallback
        );
      }
    } else {
      localStorage.removeItem("CNPJ_ADDED");
      postPjVincular(payload, successCallback, errorCallback);
    }

  }

  function removerCnpj(cnpj) {
    setEmpresasTable(empresasTable.filter(e => e.cnpj !== cnpj))
    isEmpresaParceira(false)
    $(cadastroEmpresaRef.current).removeAttr("disabled");
    $(adicionarEmpresaRef.current).removeClass("disabled");
  }

  return (
    <div
      data-sb-categoria="modal de cadastro PJ"
      className="sb-components__modal"
      ref={modalCadPjRef}
      id="modal-cad-pj"
    >
      <div className="sb-components__modal__wrapper">
        <div className="sb-components__modal__wrapper__content">
          {empresasCadastradas ? (
            <span
              className="sb-components__modal__close"
              onClick={finishCadastroPJ}
              onKeyPress={finishCadastroPJ}
              data-sb-acao="clique"
              data-sb-rotulo="Fechar - ícone"
            />
          ) : (
            <span
              className="sb-components__modal__close"
              onClick={cancelarCadastroPJ}
              onKeyPress={cancelarCadastroPJ}
              data-sb-acao="clique"
              data-sb-rotulo="Fechar - ícone"
            />
          )}
          <span className="sb-components__modal__wrapper__content__title__h1 sb-components__modal__wrapper__content__title__h1-blue">
            Adicionar CNPJ
          </span>
          <div className="sb-utilities__clearfix">
            {modalState === "OK" ? (
              <SuccessModal
                closeModal={() => finishCadastroPJ()}
                handleAdicionarOutroCnpj={() => setModalState("RESET")}
              />
            ) : modalState === "INVALID" ? (
              <ErrorModal
                message={
                  "CNPJ inativo/baixado na Receita Federal! O atendimento do Sebrae a empresas é destinado apenas a CNPJs com situação cadastral ativa"
                }
                closeModal={() => cancelarCadastroPJ()}
                handleAdicionarOutroCnpj={() => setModalState("RESET")}
              />
            ) : modalState === "COD_INVALID_422" ? (
            <ErrorModalInvalid422
              message={
                "Por favor, verifique essa informação e tente novamente."
              }
              closeModal={() => reOpenModal()}
            />
            ) : modalState === "INEXISTENTE" ? (
              <CadastrarEmpresaModal
                cadastrarEmpresaRef={cadastrarEmpresaRef}
                cadastrarEmpresaCpe={cadastrarEmpresaCpe}
                setModalState={() => setModalState("RESET")}
              />
            ) : modalState === "LOADING" ? (
              <LoadingModal />
            ) : (
              <>
                <HeaderModal
                  modalState={modalState}
                  messagePJ={messagePJ}
                  mensagemErroCnpj={mensagemErroCnpj}
                  cnpjShowing={cnpjShowing}
                  cnpjInputRef={cnpjInputRef}
                  cadastroEmpresaRef={cadastroEmpresaRef}
                  adicionarEmpresaRef={adicionarEmpresaRef}
                  pjErrorRef={pjErrorRef}
                  adicionarEmpresa={adicionarEmpresa}
                  handleCnpj={handleCnpj}
                />
                <div className="sb-components-accordion">
                  <ul>
                    <Empresas
                      vinculoEmpresaTipos={vinculoEmpresaTipos}
                      empresas={empresasTable}
                      state={state}
                      definirComoPrincipal={definirComoPrincipal}
                      setDefinirComoPrincipal={setDefinirComoPrincipal}
                      removerCnpj={removerCnpj}
                      displayAccordionContent = {displayAccordionContent}
                      displayAccordionContentShowError = {displayAccordionContentShowError}
                      displayAccordionContentIsShowError = {displayAccordionContentIsShowError}
                    />
                  </ul>
                </div>
                {empresaPorte !== "micro" && empresaPorte !== "pequeno" && empresaPorte !== "MEI" ? (
                  <EmpresasGrandeMedioPorte
                    empresaName={empresaName}
                    empresaPorte={empresaPorte}
                    modalPjInfoRef={modalPjInfoRef}
                    isEmpresaParceira={isEmpresaParceira}
                  />
                ) : (
                  <>{isEmpresaParceira(false)}</>
                )}
                <FooterModal
                  empresasCadastradas={empresasCadastradas}
                  empresasTable={empresasTable}
                  cadastrarEmpresas={cadastrarEmpresas}
                  finishCadastroPJ={finishCadastroPJ}
                  cancelarCadastroPJ={cancelarCadastroPJ}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
