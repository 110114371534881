import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../shared/context/AppContext";
import $ from "jquery";

import Child from "./child";
import { useRef } from "react";
import { useCpe } from "../modalCadastroPJ/useCpe";

const ModalVinculoEmpresa = ({ setModalPJShow, empresasCPE }) => {
  const [state] = useGlobalState();
  const [companies, setCompanies] = useState([]);
  const [cnpjPrincipal, setCnpjPrincipal] = useState("");
  const [bindStatus, setBindStatus] = useState("");
  const [alert, setAlert] = useState(null);
  const [isLoading, setIsLoading] = useState();
  const modalVinculoRef = useRef(null);
  const { postPjVincular, getEmpresaPorCpf, putPrincipal } = useCpe();

  const [hadEmpresas, setHadEmpresa] = useState(false);

  useEffect(() => verifyUserCompanies(), []);

  function incentivoCadastroPJ(shouldAppear) {
    let accessLogado =
      parseInt(
        localStorage.getItem(`access-${state.keycloak.tokenParsed.sub}`)
      ) || 0;
    if (
      accessLogado % 5 === 0 &&
      !empresasCPE &&
      shouldAppear
    ) {
      setModalPJShow(true);
    }
    localStorage.setItem(
      `access-${state.keycloak.tokenParsed.sub}`,
      parseInt(accessLogado) + 1
    );
  }

  function closeVinculoEmpresa() {
    $(modalVinculoRef.current).fadeOut("fast").removeClass("active");
  }

  function openVinculoEmpresa() {
    $(modalVinculoRef.current).fadeIn("fast").addClass("active");
  }

  function handleAdicionarOutroCnpj() {
    if (bindStatus == "success") {
      putPrincipal(cnpjPrincipal);
      localStorage.setItem("CNPJ_ADDED", "true");
    }
    closeVinculoEmpresa();
    setModalPJShow(true);
    localStorage.setItem("shouldRefreshPage", true);
  }

  function showInfoAlert(message) {
    setAlert(
      <div className="sb-components__alert info">
        <strong>{message}</strong>
      </div>
    );
  }

  function closeModal() {
    if (bindStatus == "success") {
      putPrincipal(cnpjPrincipal, () => window.location.reload());
    } else {
      closeVinculoEmpresa();
    }
  }

  function bind() {
    if (!cnpjPrincipal) {
      showInfoAlert("Favor selecionar uma empresa principal");
      return;
    }
    const successCallback = (data) => {
      setIsLoading(false);
      setBindStatus("success");
    };
    const errorCallback = (error) => {
      setIsLoading(false);
      setBindStatus("failed;Houve algum problema. Tente novamente mais tarde");
    };
    const payload = JSON.stringify(companies);
    setIsLoading(true);
    postPjVincular(payload, successCallback, errorCallback);
  }

  /**
    99: "mei",
    1: "médio",
    2: "micro",
    3: "pequeno",
    4: "grande",
    98: "outro",
    5: "outro",
  */
  function verifyUserCompanies() {
    if (empresasCPE && empresasCPE.length > 0) return;
    const successCallback = (res) => {
      if (res.status !== 200 || res.data.length < 1) return;
      const empresas = res.data
        .filter(
          (empresa) =>
            empresa.situacaoCadastral == null ||
            empresa.situacaoCadastral == "02" ||
            empresa.situacaoCadastral == "04" ||
            empresa.situacaoCadastral == "99"
        )
        .filter((empresa) => [99, 2, 3].find((p) => p == empresa.porte))
        .map((empresa) => ({ ...empresa, parceira: false }));
      if (empresas.length >= 1) {
        setHadEmpresa(true);
        setCnpjPrincipal(empresas[0].cnpj);
        incentivoCadastroPJ(false);
        setCompanies(empresas);
        openVinculoEmpresa();
      } else {
        setHadEmpresa(false);
        incentivoCadastroPJ(true);
      }
    };
    getEmpresaPorCpf(state.keycloak.tokenParsed.cpf)
      .then(successCallback)
      .catch((error) => console.log("ERRO NO VERIFY: " + error));
  }

  const perfilStorageKey = `_userPerfil-${state.keycloak.tokenParsed.sub}`;

  function isModalIncentivoOpen() {
    const perfil = localStorage.getItem(perfilStorageKey);
    return !!perfil && companies && companies.length;
  }

  function removerCnpj(cnpj) {
    if(cnpj === cnpjPrincipal) {
      setCnpjPrincipal('')
    }
    const cpms = companies.filter((c) => c.cnpj !== cnpj);
    setCompanies(cpms);
    if (cpms.length === 0 && hadEmpresas) {
      setModalPJShow(true);
    }
  }

  return isModalIncentivoOpen() ? (
    <></>
  ) : (
    <></>
  );
};

export default ModalVinculoEmpresa;
