import updateToken from "../../../updateToken";

import { useGlobalState } from "../context/AppContext";

import axios from "axios";
import { useCallback, useRef, useState } from "react";

export const useCpe = () => {
  const [state] = useGlobalState();

  function postPjVincular(payload, callback, err) {
    axios({
      method: "post",
      url: `${state.env.BACKEND_URL}/v1/cpe/pj/vincular`,
      headers: { "Content-Type": "application/json" },
      data: payload,
    })
      .then(callback)
      .catch(err);
        
  }

  function deletePjVinculo(cnpj, callback, err) {
    axios.delete(`${state.env.BACKEND_URL}/v1/cpe/pj/${cnpj}`)
    .then(callback)
    .catch(err);
  }

  function getPj(cnpj, callback, err) {
    updateToken(state.keycloak, () => {
      axios
        .get(`${state.env.BACKEND_URL}/v1/cpe/pj/${cnpj}?allcontent=true`, {
          validateStatus(status) {
            return status < 500;
          },
        })
        .then(callback)
        .catch(err);
    });
  }

  const getPjs = (cnpjs = [], callback, err) => {
    const urls = cnpjs.map(
      (cnpj) => `${state.env.BACKEND_URL}/v1/cpe/pj/${cnpj}?allcontent=true`
    );
    const promises = urls.map(url => axios.get(url));
    updateToken(state.keycloak, () =>
      Promise.allSettled(promises)
        .then(callback)
        .catch(err)
    );
  };

  function putPrincipal(cnpj, callback, err) {
    if (!err) {
      err = (error) => console.log(error);
    }
    axios
      .put(`${state.env.BACKEND_URL}/v1/cpe/pj/${cnpj}`)
      .then(callback)
      .catch(err);
  }

  // Funções compostas
  function vincularPjSettingPrincipal(payload, cnpj, callback, err) {
    postPjVincular(payload, () => putPrincipal(cnpj, callback, err), err);
  }

  function getEmpresaPorCpf(cpf) {
    if (!cpf) return;
    const cleanedCpf = cpf.replace(/\D/g, "");
    return axios.get(
      `${state.env.BACKEND_URL}/v1/cpe/pj/buscaEmpresaPorCpf/${cleanedCpf}`
    );
  }

  function getEmpresasVinculadas(callback, err) {
    axios.get(`${state.env.BACKEND_URL}/v1/cpe/pj/vincular`)
      .then(callback)
      .catch(err);
  }

  async function getVinculoEmpresaTipo() {
    return await axios.get(`${state.env.BACKEND_URL}/v1/cpe/vinculo-empresa/tipo`)
  }

  return {
    postPjVincular: useCallback(postPjVincular, []),
    deletePjVinculo: useCallback(deletePjVinculo, []),
    getPj: useCallback(getPj, []),
    putPrincipal: useCallback(putPrincipal, []),
    vincularPjSettingPrincipal: useCallback(vincularPjSettingPrincipal, []),
    getEmpresaPorCpf: useCallback(getEmpresaPorCpf, []),
    getPjs: useCallback(getPjs, []),
    getEmpresasVinculadas: useCallback(getEmpresasVinculadas, []),
    getVinculoEmpresaTipo: useCallback(getVinculoEmpresaTipo, [])
  };
};
